<template>
  <div class="px-4 sm:px-6 lg:px-0 col-span-12 lg:col-span-8 mt-4 lg:mt-0">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'Account'
};
</script>

<style scoped></style>
